(function () {

  // common
  var $menu = $('#menu');
  var $list = $menu.find('.li');
  var ie = /msie/i.test(navigator.userAgent);

  
  // if (ie) {
  $('.td').each(function() {
    $(this).find('.bdr').height($(this).height());
  });
  // }
  

  $('#top').each(function () {
    var $sv = $('.sv');

    $sv.stop(true).hover(function () {
      $(this).find('.scale').transit({scale: 1.3}, 150, 'easeInOutQuad');
    }, function () {
      $(this).find('.scale').transit({scale: 1}, 150, 'easeInOutQuade');
    });

  });


  $list.each(function () {
    let $el = $(this);
    let $category = $el.find('.category');
    let $sub = $el.find('.subcategory');

    let toggleSubcategory = function (e, name) {
      if (name) {
        if ($category.hasClass('active')) {
          $category.removeClass('active');
          $sub.slideToggle(200);
        }
        return false
      }
      if ($category.hasClass('active')) {
        $category.removeClass('active');
      } else {
        $category.addClass('active');
        $list.not($el).trigger('hide', 'next');
      }
      $sub.slideToggle(200);
    };

    $el.on('hide', toggleSubcategory);

    $category.on('click', function () {
      if ($sub.length) {
        toggleSubcategory();
      } else {
        location.href = $category.data('href') || '/';
      }
    });
  });

  $('.slider').each(function () {
    let $el = $(this);
    let $slick = $el.find('.blocks');

    $slick.slick({
      dots: true,
      arrows: false,
      speed: 250,
      swipe: false
    });

    $el.on('click', '.arrow-left', function () {
      $slick.slick('slickPrev');
    });

    $el.on('click', '.arrow-right', function () {
      $slick.slick('slickNext');
    });
  });

  $('#contact').each(function() {
    var $el = $(this);
    var $agree = $el.find('#checkbox');
    var $check  = $el.find('.check');
    var $zipcode = $el.find('#zipcode');
    var api = 'http://api.zipaddress.net/?callback=?&zipcode=';

    $check.on('click', function() {
      if ($agree.is(':checked')) {
        location.href = $(this).attr('href');
      }
      return false;
    });
    $('.search').on('click', function() {
      var value = $zipcode.val();
      if (value) {
        $.getJSON(api + value, function(rs) {
          if (rs) {
            $('#address').val(rs.fullAddress);
          }
        });
      }
    });
  });


  $('.form .reset').on('click', function () {
    $('form').find('[type="text"]').each(function () {
      $(this).val('');
    });
    return false;
  });

  $('.confirm .reset').on('click', function () {
    history.back();
    return false;
  });

})();
